table {
  @include font-feature-settings("kern", "liga", "tnum");
  border-collapse: collapse;
  margin: $small-spacing 0;
  // table-layout: fixed; // removed to fix gist embeds
  width: 100%;
}

th {
  border-bottom: 1px solid darken($base-border-color, 15%);
  border-right: 1px solid $base-border-color;
  font-weight: 600;
  padding: $small-spacing 0;
  text-align: left;
}

td {
  // border-bottom: $base-border; // removed to fix gist embeds
  border-right: 1px solid $base-border-color;
}

td:last-child, th:last-child {
  border-right: none;
}

.gist-data td {
  border-right: none;
}

tr,
td,
th {
  padding: 0 $small-spacing;
  vertical-align: middle;
}
